<template>
  <div>
    <div class="root_cont yellow_bg">
      <div class="container">
        <h4 class="fw-400 text-center narrow">
          <span v-html="t.text_1"> </span>
        </h4>

          <div
            ref="metro_chart_cont"
            class="metro_chart_cont mt-3"
          >
            <MetroChart
              v-if="in_viewport"
              :metro_animation_time="6000"
            ></MetroChart>
          </div>

        <div class="text-center">
          <button
            class="btn btn-lg button_slide_blue mt-4"
            @click="$router.push({ name: 'locksmith' })"
          >
            <span v-html="t.learn_how"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "text_1": "We will work on your behalf to generate income from a valid claim to land without clean title.",
    "learn_how": "LEARN HOW"
  },
  "hr": {
    "text_1": "Pomažemo Vam ostvariti prihod od valjanog prava na zemljište bez čistog vlasništva.",
    "learn_how": "SAZNAJ KAKO"
  },
  "es": {
    "text_1": "Trabajaremos en su nombre para generar ingresos de un reclamo válido de terreno sin título limpio.",
    "learn_how": "APRENDER CÓMO"
  }
}
//</i18n>

import "intersection-observer";
import MetroChart from "../MetroChart";

export default {
  name: "HomeSolutionMobile",
  components: { MetroChart },
  data() {
    return {
      in_viewport: false,
      t: t[this.$lang]
    };
  },
  mounted() {
    const vm = this;

    {
      const io_options = {
        rootMargin: "0px",
        threshold: 0
      };

      const io_callback = entries => {
        vm.in_viewport = !!entries[0].isIntersecting;
      };

      let io_observer = new IntersectionObserver(io_callback, io_options);
      io_observer.observe(vm.$refs.metro_chart_cont);
    }
  }
};
</script>

<style scoped>
.root_cont {
  padding: 40px 0;
  overflow: hidden;
}
.btn {
  border-color: rgb(4, 43, 93);
  color: rgb(4, 43, 93);
  width:280px;
}

.metro_chart_cont {
  margin-left: -50px;
  margin-right: -50px;
  height: 400px;
}

.narrow {
  width: 500px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
h4 {
  line-height: 150%;
}

@media (max-width: 576px) {
  .metro_chart_cont {
    margin-left: -15px;
    margin-right: -15px;
    height: 55vw;
    padding: 10px 0;
  }

  .btn {
    width: 80%;
  }
}

</style>
