<template>
  <div class="wrapper">
    <div class="autocomplete-cont">
      <input
        ref="main_input"
        class="search"
        type="text"
        disabled
        :placeholder="
          $store.state.responsive.window_width < 481
            ? t.journey_placeholder_mobile
            : t.journey_placeholder_desktop
        "
        @keyup="keyupHandler($event)"
        @focus="scrollToInput"
      />
      <div
        ref="res_cont"
        class="res-cont-cont"
        v-if="result_display && results.length > 0"
      >
        <div class="res-cont">
          <div
            v-for="(d, i) in results"
            class="option"
            :class="{ active: option_focus == i }"
            @click="selected(d)"
            :title="d.text"
          >
            {{ d.text }}
          </div>
        </div>
      </div>
    </div>
    <button
      class="search btn ta_disabled"
      :style="{ 'background-color': button_search_bg_color || null }"
      @click="run(input_text)"
    >
      <img
        v-if="$store.state.responsive.window_width < 481"
        src="/static/images/Arrow.svg"
        class="arrow_right"
      />
      <span v-else>
        <span v-html="t.search"></span>
      </span>
    </button>
  </div>
</template>

<script>
import { TweenLite, TweenMax, Linear } from "gsap/all";
import { TextPlugin } from "gsap/TextPlugin";

const t =
  //<i18n>
{
  "en": {
    "journey_placeholder_mobile": "Explore your land DNA",
    "journey_placeholder_desktop": "Enter your family name to explore your land DNA...",
    "search": "SEARCH"
  },
  "hr": {
    "journey_placeholder_mobile": "Istražite vlasnička prava",
    "journey_placeholder_desktop": "Upišite prezime za pregled mogućih vlasničkih prava...",
    "search": "PRETRAŽI"
  },
  "es": {
    "journey_placeholder_mobile": "Explora su ADN catastral",
    "journey_placeholder_desktop": "Introduzca su apellido para explorar su ADN catastral…",
    "search": "BUSCAR"
  }
}
//</i18n>

export default {
  name: "JourneyAutoComplete",
  props: ["fetchResults", "run", "button_search_bg_color", "scroll_to_input"],
  data() {
    return {
      t: t[this.$lang],
      input_text: "",
      results: [],
      result_display: false,
      loading: false,
      res_num: 10,

      last_keyCode: null,
      option_focus: -1,

      input_timeout: setTimeout(() => {}, 1)
    };
  },
  created() {
    const vm = this;

    document.addEventListener("click", function(e) {
      vm.closeResCont(e.target);
    });
  },
  beforeUnmount() {
    const vm = this;

    document.removeEventListener("click", function(e) {
      vm.closeResCont(e.target);
    });

    try {
      vm.observer.disconnect();
    } catch (e) {}
  },
  mounted() {
    const vm = this;

    vm.animatePlaceholder();
  },
  watch: {},
  computed: {
    searchBtnLbl() {
      const vm = this;
      return vm.$store.state.responsive.window_width < 481
        ? "<img src='/static/images/Arrow.svg' class='arrow_right'>"
        : "SEARCH";
    }
  },
  methods: {
    selected(d) {
      const vm = this;
      vm.input_text = d.text;
      vm.result_display = false;

      vm.run(vm.input_text);
    },
    setResponseData(data) {
      const vm = this;
      vm.results = data;
    },
    keyupHandler(e) {
      const vm = this;

      vm.input_text = e.target.value;
      vm.result_display = true;

      {
        vm.$emit("input", vm.input_text);

        clearTimeout(vm.input_timeout);
        vm.input_timeout = setTimeout(() => {
          if (vm.input_text.length > 0)
            vm.fetchResults(vm.input_text, vm.setResponseData);
          else vm.setResponseData([]);
        }, 300);
      }

      {
        vm.last_keyCode = e.keyCode;

        if (e.keyCode == 40) {
          if (vm.option_focus < vm.results.length - 1) vm.option_focus++;
        } else if (e.keyCode == 38) {
          //up
          if (vm.option_focus > 0) vm.option_focus--;
        } else if (e.keyCode == 13) {
          /*If the ENTER key is pressed, prevent the form from being submitted,*/
          if (vm.option_focus > -1) {
            e.preventDefault();
            /*and simulate a click on the "active" item:*/
            vm.$el.querySelectorAll(".option")[vm.option_focus].click();
          } else {
            vm.closeResCont(null);
            vm.run(vm.input_text);
          }
        } else {
        }
      }
    },
    closeResCont(elm) {
      const vm = this;

      const isDescendant = (parent, child) => {
        var node = child.parentNode;
        for (let i = 0; i < 3; i++) {
          // check 3 layers deep
          if (node == parent) {
            return true;
          }
          node = node.parentNode;
        }
        return false;
      };

      if (
        !elm ||
        (!isDescendant(vm.$refs.res_cont, elm) && elm !== vm.$refs.main_input)
      ) {
        vm.result_display = false;

        vm.option_focus = -1;
      }
    },

    animatePlaceholder() {
      const vm = this;

      if (!window.IntersectionObserver) return;

      const text = vm.$refs.main_input.getAttribute("placeholder");
      vm.$refs.main_input.placeholder = "";

      vm.observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const { isIntersecting, intersectionRatio } = entry;

            if (isIntersecting === true && intersectionRatio > 0) {
              const tween_obj = { text_len: 0 };

              TweenMax.to(tween_obj, 2, {
                text_len: text.length,
                delay: 1
              }).eventCallback("onUpdate", function() {
                try {
                  vm.$refs.main_input.placeholder = text.slice(
                    0,
                    tween_obj.text_len
                  );
                } catch (e) {}
              });

              vm.observer.disconnect();
            }
          });
        },
        { threshold: 0 }
      );

      vm.observer.observe(vm.$refs.main_input);
    },

    scrollToInput() {
      this.result_display = true;
      if (this.scroll_to_input === false) return;

      const element = document.getElementById("start_your_journey"),
          yOffset = this.$store.state.responsive.window_width > 480 ? -100 : -70,
          y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }
};
</script>

<style scoped>
.wrapper {
  width: 680px;
  max-width: 95%;
  position: relative;
  text-align: left;
  margin: auto;
  display: flex;
}

.autocomplete-cont {
  position: relative;
  height: max-content;
  overflow: visible;
  display: inline-block;
  color: rgb(4, 43, 93);
  width: calc(100% - 110px);
}

input.search {
  height: 50px;
  width: 100%;
  padding-left: 1em;
  font-size: 16px;
  border: solid rgb(4, 43, 93) 1px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  transition: ease-in-out 0.5s;
}

button.search {
  height: 50px;
  width: 110px;
  background-color: rgb(4, 43, 93);
  color: #fffff3;
  font-weight: bolder;

  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px;
}

.arrow_right {
  transform: rotate(-90deg);
  width: 24px;
}

input.search:focus {
  outline: 0;
}

button.search:hover,
button.search:focus {
  background-color: rgb(4, 43, 93);
  color: #fffff3;
}

.autocomplete-cont .res-cont-cont {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 100;
  text-align: left;
}

.autocomplete-cont .res-cont {
  position: relative;
  top: 1px;
  border: solid rgb(4, 43, 93) 1px;
  border-radius: 3px;
}

.autocomplete-cont .option {
  padding: 0.5em;
  padding-left: 1em;
  /*border: 1px black solid;*/
  cursor: pointer;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}

.autocomplete-cont .option:hover,
.autocomplete-cont .option.active {
  background-color: rgb(4, 43, 93);
  color: white;
}

@media (max-width: 576px) {
  .autocomplete-cont {
    width: calc(100% - 50px);
  }

  button.search {
    width: 50px;
  }
}
</style>
