<template>
  <div class="root_cont">
    <div class="carousel">
      <div class="carousel-controls">
        <div class="carousel-arrows">
          <div
            class="arrow prev-slide"
            @click="carouselChange(carousel_active - 1)"
          >
            <span><img src="/static/images/Arrow.svg" alt=""/></span>
          </div>
          <div
            class="arrow next-slide"
            @click="carouselChange(carousel_active + 1)"
          >
            <span><img src="/static/images/Arrow.svg" alt=""/></span>
          </div>
        </div>
        <div class="carousel-dots">
          <span
            class="carousel-dot"
            id="img-dot-1"
            @click="carouselChange(1)"
            :class="{ active: carousel_active == 1 }"
          ></span>
          <span
            class="carousel-dot"
            id="img-dot-2"
            @click="carouselChange(2)"
            :class="{ active: carousel_active == 2 }"
          ></span>
          <span
            class="carousel-dot"
            id="img-dot-3"
            @click="carouselChange(3)"
            :class="{ active: carousel_active == 3 }"
          ></span>
        </div>
      </div>

      <div class="carousel_elements">
        <div class="carousel_item _1" v-if="carousel_active == 1">
          <div class="carousel-image"></div>
          <div class="carousel-image-grid" :class="{ active: show_grid }"></div>
          <div class="overlay" :class="{ active: show_overlay }">
            <div class="overlay_text">
              <div class="overlay_text_big">
                <h1 class="main_head">
                  <span>{{ t.carousel_1_h1_1 }}</span>
                  <span> {{ t.carousel_1_h1_2 }}</span>
                </h1>
              </div>
              <div class="overlay_text_medium">
                <h4 class="under_main_head">
                  <span>{{ t.carousel_1_h4_1 }}</span>
                  <span> {{ t.carousel_1_h4_2 }}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel_item _2" v-if="carousel_active == 2">
          <MetroChart :metro_animation_time="metro_animation_time"></MetroChart>
          <div class="overlay" :class="{ active: show_overlay }">
            <div class="overlay_text">
              <div class="overlay_text_big">
                <h1 class="main_head">
                  <span>{{ t.carousel_2_h1_1 }}</span>
                  <span> {{ t.carousel_2_h1_2 }}</span>
                  <span> {{ t.carousel_2_h1_3 }}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel_item _3" v-if="carousel_active == 3">
          <div class="carousel-image"></div>
          <div class="carousel-image-grid" :class="{ active: show_grid }"></div>
          <div class="overlay" :class="{ active: show_overlay }">
            <div class="overlay_text">
              <div class="overlay_text_big">
                <h1 class="main_head">
                  <span>{{ t.carousel_3_h1_1 }}</span>
                  <span> {{ t.carousel_3_h1_2 }}</span>
                  <span> {{ t.carousel_3_h1_3 }}</span>
                </h1>
              </div>
              <div class="overlay_text_medium">
                <h4 class="under_main_head">
                  <span>{{ t.carousel_3_h4_1 }}</span>
                  <span> {{ t.carousel_3_h4_2 }}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "carousel_1_h1_1": "CHALLENGING",
    "carousel_1_h1_2": "THE AGE-OLD ASSUMPTION...",
    "carousel_1_h4_1": "... that resolving land title issues is too hard,",
    "carousel_1_h4_2": "too complex and too costly to achieve",
    "carousel_2_h1_1": "WE USE ADVANCED TECHNOLOGY",
    "carousel_2_h1_2": "AND AN INEXORABLE PROCESS",
    "carousel_2_h1_3": "TO REALISE VALUE FROM LAND",
    "carousel_3_h1_1": "AN ESTIMATED 10% OF LAND IN",
    "carousel_3_h1_2": "CROATIA IS COMMERCIALLY",
    "carousel_3_h1_3": "UNVIABLE DUE TO UNCLEAR TITLE",
    "carousel_3_h4_1": "Solving this issue will release value for owners and unlock",
    "carousel_3_h4_2": "inward investment to support the economy, jobs and growth"
  },
  "hr": {
    "carousel_1_h1_1": "SUOČAVAMO SE",
    "carousel_1_h1_2": "S UKORIJENJENIM STAVOM...",
    "carousel_1_h4_1": "... da je uspostavljanje čistog zemljišnog vlasništva u Republici Hrvatskoj",
    "carousel_1_h4_2": "gotovo nemoguća misija",
    "carousel_2_h1_1": "KORISTIMO NAPREDNU TEHNOLOGIJU I",
    "carousel_2_h1_2": "NEZAUSTAVLJIV PROCES",
    "carousel_2_h1_3": "ZA OSTVARENJE VRIJEDNOSTI ZEMLJIŠTA",
    "carousel_3_h1_1": "OKO 10% ZEMLJIŠTA U HRVATSKOJ",
    "carousel_3_h1_2": "NEISKORIŠTENO JE",
    "carousel_3_h1_3": "ZBOG NERIJEŠENIH VLASNIČKIH PRAVA",
    "carousel_3_h4_1": "Rješavanje ovog problema osigurat će prihode vlasnicima i otključati",
    "carousel_3_h4_2": "ulazne investicije za poticaj gospodarskog rasta i novih zapošljavanja"
  },
  "es": {
    "carousel_1_h1_1": "DESAFIANDO",
    "carousel_1_h1_2": "LA SUPOSICIÓN ERRÓNEA…",
    "carousel_1_h4_1": "…de que resolver los problemas de titularidad de terrenos es demasiado difícil,",
    "carousel_1_h4_2": "demasiado complejo y demasiado costoso de lograr",
    "carousel_2_h1_1": "UTILIZAMOS TECNOLOGÍA AVANZADA",
    "carousel_2_h1_2": "Y UN PROCESO INEXORABLE",
    "carousel_2_h1_3": "PARA REALIZAR EL VALOR DE TERRENOS",
    "carousel_3_h1_1": "SE ESTIMA QUE UN 10 % DE LOS TERRENOS",
    "carousel_3_h1_2": "EN CROACIA ES COMERCIALMENTE",
    "carousel_3_h1_3": "INVIABLE POR ASUNTOS DE TÍTULOS",
    "carousel_3_h4_1": "La solución de este problema realizará el valor para los propietarios y desbloqueará",
    "carousel_3_h4_2": "inversión extranjera para apoyar la economía, el empleo y el crecimiento"
  }
}
//</i18n>

import MetroChart from "./MetroChart";
export default {
  name: "Carousel",
  components: { MetroChart },
  data() {
    return {
      t: t[this.$lang],
      carousel_active: 1,
      show_grid: false,
      show_overlay: false,
      grid_animation_time_1: 3000,
      metro_animation_time: 6000,
      grid_animation_time_3: 2500
    };
  },
  mounted() {
    const vm = this;
    vm.carouselChange(1);
  },
  methods: {
    carouselChange(new_value) {
      const vm = this;

      if (new_value > 3) new_value = 1;
      else if (new_value < 1) new_value = 3;

      vm.show_grid = false;
      vm.show_overlay = false;
      vm.carousel_active = new_value;

      clearTimeout(vm.show_overlay_timeout);
      clearTimeout(vm.carousel_change_timeout);

      if (vm.carousel_active !== 2) {
        let img1_src;
        let img2_src;
        if (vm.carousel_active === 1) {
          img1_src = "/static/images/PodlogaPula.jpg";
          img2_src = "/static/images/PodlogaPula-grid.png";
        } else if (vm.carousel_active === 3) {
          img1_src = "/static/images/3-animacija.jpg";
          img2_src = "/static/images/3-grid.png";
        }

        const img1 = new Image();
        img1.onload = function() {
          const elm = document.querySelector(
            ".carousel_item._" + vm.carousel_active + " .carousel-image"
          );
          if (!elm) return;
          elm.style["background-image"] = 'url("' + img1_src + '")';
          if (img2.complete) startAnim();
        };
        const img2 = new Image();
        img2.onload = function() {
          const elm = document.querySelector(
            ".carousel_item._" + vm.carousel_active + " .carousel-image-grid"
          );
          if (!elm) return;
          elm.style["background-image"] = 'url("' + img2_src + '")';
          if (img1.complete) startAnim();
        };

        img1.src = img1_src;
        img2.src = img2_src;
        if (img1.complete) img1.onload();
        if (img2.complete) img2.onload();
      } else {
        startAnim();
      }

      function startAnim() {
        vm.show_grid = true;

        clearTimeout(vm.carousel_change_timeout);
        clearTimeout(vm.show_overlay_timeout);
        vm.show_overlay_timeout = setTimeout(
          () => {
            vm.show_overlay = true;
            {
              let next_value = vm.carousel_active + 1;
              if (next_value > 3) next_value = 1;
              else if (next_value < 1) next_value = 3;

              clearTimeout(vm.carousel_change_timeout);
              vm.carousel_change_timeout = setTimeout(
                () => {
                  vm.carouselChange(next_value);
                },
                vm.carousel_active === 1
                  ? 7000
                  : vm.carousel_active === 2
                  ? 4000
                  : 9000
              );
            }
          },
          vm.carousel_active === 1
            ? vm.grid_animation_time_1
            : vm.carousel_active === 2
            ? vm.metro_animation_time
            : vm.grid_animation_time_3
        );
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
.carousel {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.carousel_elements {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.carousel_item {
  height: 100%;
  color: #fffff3;
}

.carousel_item .overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  transition: opacity 1000ms;
}

.carousel_item .overlay.active {
  opacity: 1;
}

.carousel_item._1 .overlay {
  background-color: rgba(4, 43, 93, 0.4);
}

.carousel_item._2 .overlay {
  background-color: rgba(4, 43, 93, 0.8);
}

.carousel_item._3 .overlay {
  background-color: rgba(4, 43, 93, 0.4);
}

.overlay_text {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.carousel-image,
.carousel-image-grid {
  height: 100%;
  width: 100%;
  background-color: rgba(4, 43, 93, 1); /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: absolute;
  max-height: 500px;

  background-attachment: fixed; /* parallax */
}

@keyframes grid-show-1 {
  from {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@keyframes grid-show-3 {
  from {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.carousel-image-grid {
  background-color: transparent;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
}

.carousel_item._1 .carousel-image-grid.active {
  animation: grid-show-1 4000ms cubic-bezier(0, 0, 0.3, 1);
  animation-fill-mode: forwards;
}

.carousel_item._3 .carousel-image-grid.active {
  animation: grid-show-3 3000ms cubic-bezier(0, 0, 0.3, 1);
  animation-fill-mode: forwards;
}

.carousel_item._2 {
  background-image: linear-gradient(to top, rgb(229, 211, 187) 0%, #fffff3 25%);
}

.carousel_item img {
  width: 100%;
}

.carousel-controls {
  height: 100%;
}

.carousel-arrows {
  font-size: 100px;
  color: #fffff3;
  display: flex;
  height: 100%;
}

.carousel-arrows .arrow {
  z-index: 3;
  transition: opacity 0.2s;
  cursor: pointer;
  width: 50%;
  height: 100%;

  opacity: 0.5;
}

.carousel-arrows .arrow span {
  display: block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 40px;
  height: min-content;
}

.carousel-arrows .arrow:hover {
  opacity: 1;
}

.carousel-arrows img {
  width: 130px;
}

.carousel-arrows .prev-slide span {
  text-align: left;
  left: 0;
}

.carousel-arrows .next-slide span {
  text-align: right;
  right: 0;
}

.next-slide img {
  transform: rotate(-90deg);
}

.prev-slide img {
  transform: rotate(90deg);
}

.carousel-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 4;
  text-align: center;
}

.carousel-dots .carousel-dot {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: solid #fffff3 1.5px;
  border-radius: 50%;
  background-color: transparent;
  margin: 10px;
  cursor: pointer;
}

.carousel-dots .carousel-dot.active {
  background-color: #fffff3;
}

.overlay_text {
  width: 100%;
  padding: 0 10px;
}

.overlay_text h4 {
  font-weight: 400 !important;
}

.overlay_text_big {
  font-size: 1.2em;
}

.overlay_text_big span {
  display: block;
}

.overlay_text_medium span {
  display: block;
  line-height: 1.3;
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1200px) {
  .overlay_text_big {
  }

  .overlay_text_medium {
  }
}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {
  .overlay_text_big {
  }

  .overlay_text_medium {
  }

  .carousel {
  }
}

@media (max-width: 1200px) {
  .carousel-image,
  .carousel-image-grid {
    background-attachment: initial; /* because safari ipad */
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 768px) {
  .overlay_text_big {
  }

  .overlay_text_medium {
  }

  .overlay_text_big span {
    display: inline;
  }

  .overlay_text_medium span {
    display: inline;
  }

  .carousel-arrows .arrow span {
    display: none;
  }
}

@media (max-width: 576px) {
  .overlay_text {
    top: 43%;
  }

  .overlay_text > div {
  }

  .overlay_text_big {
  }

  .overlay_text_medium {
  }

  .main_head {
    font-size: 1.8rem !important;
  }

  .under_main_head {
    font-size: 1.2rem !important;
  }

  .carousel {
    height: 350px;
  }

  .carousel-image,
  .carousel-image-grid {
    max-height: 350px;
    background-attachment: initial;
  }

  .carousel-dots {
    position: absolute;
    bottom: 7px;
  }
}
</style>
