<template>
  <div class="root_cont">
    <div class="container">
      <h2 class="text-center">FAQ</h2>

      <div class="mt-4">
        <template v-for="(d, i) in faq_content">
          <div class="acc_card">
            <div
              class="acc_head row"
              @click="
                accordion_active == i
                  ? (accordion_active = null)
                  : (accordion_active = i)
              "
            >
              <div class="col-10">
                <h5>{{ d.h }}</h5>
              </div>
              <div class="side_arrow_cont col-2 text-center">
                <img
                  src="/static/images/Arrow.svg"
                  alt=""
                  class="side_arrow"
                  :class="{ active: accordion_active == i }"
                />
              </div>
            </div>
            <div class="acc_body" :class="{ active: accordion_active == i }">
              <template v-if="d.html">
                <div v-html="d.html"></div>
              </template>
              <template v-else>
                <p v-for="p in d.p" v-html="p"></p>
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="text-center mt-5">
        <button
          @click="$router.push({ name: 'faq' })"
          class="btn btn-lg button_slide list_all"
        >
          <span>{{ t.list_all }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "q_4_h": "What can you do for me?",
    "q_4_p_1": "If you have a valid claim to land and if you wish to realise value from the sale of that land at no upfront cost to yourself, then we can help.",
    "q_9_h": "How long will it take to sell my land?",
    "q_9_p_1": "There are many factors affecting the time that the process will take. These include - as mentioned in the above answer - reaching a critical mass of land parcels within any particular community project so as to ensure the cost viability of that project.",
    "q_9_p_2": "Once we are ready to move forward and have funding secured, we aim to establish clean title within 3-4 years. Once clean title has been established, we work with all parties to maximise the value of that land ahead of its sale - a process we expect between 12 and 24 months to achieve.",
    "q_12_h": "What guarantees do I have?",
    "q_12_p_1": "As part of the Locksmith agreement, the investor will guarantee you an Initial Installment when clean title is established. This Initial Installment will reflect the risk that the investor is willing to take and will therefore be significantly less than the expected price at sale.",
    "q_12_p_2": "Once clean title has been established, we will work to maximise the value of that land as previously explained. This process will take 1-2 years depending on the complexity. Once sold and per the contract, you will then receive a further payment bringing your entire share from the overall proceeds to at least 80% of the net proceeds from sale.",
    "list_all": "LIST OF ALL FAQs"
  },
  "hr": {
    "q_4_h": "Što možete učiniti za mene?",
    "q_4_p_1": "Ako imate legitimno pravo na zemljište i želite ostvariti vrijednost od prodaje tog zemljišta – bez  ikakvih troškova unaprijed – tada Vam možemo pomoći.",
    "q_9_h": "Koliko će trajati prodaja mog zemljišta?",
    "q_9_p_1": "Brojni su čimbenici koji utječu na trajanje procesa. Jedan od čimbenika, spomenut ranije, je dosezanje kritične mase površine zemljišta unutar određenog područja da bismo osigurali financijsku održivost projekta.",
    "q_9_p_2": "Kad se odlučimo na sljedeći korak i osiguramo sredstva, cilj nam je uspostava čistog  vlasništva u roku od 3 do 4 godine. Čim se uspostavi čisto vlasništvo, surađivat ćemo sa svim stranama kako bismo povećali vrijednost tog zemljišta prije prodaje. Očekujemo da će nam za to biti potrebno između 12 i 24 mjeseca.",
    "q_12_h": "Što mi osiguravate?",
    "q_12_p_1": "Kao dio <i>Locksmitha</i>, investitor će Vam garantirati isplatu inicijalne rate zajamčene vrijednosti kada se uspostavi čisto vlasništvo. Taj inicijalni iznos odražava investitorov rizik te je znatno niži od očekivane prodajne cijene.",
    "q_12_p_2": "Nakon ishođenja čistog vlasništva, maksimizirat ćemo vrijednost zemljišta, kao što je objašnjeno u prethodnom odgovoru. Ovisno o složenosti pojedinog slučaja, taj proces mogao bi trajati 1-2 godine. Kada prodamo zemljište, prema ugovoru ćete primiti daljnju uplatu, kojom Vaš udio u cjelokupnom procesu doseže barem 80% vrijednosti prodajne cijene.",
    "list_all": "SVA ČESTO POSTAVLJANA PITANJA"
  },
  "es": {
    "q_4_h": "¿Qué puede hacer por mí?",
    "q_4_p_1": "Si tiene un reclamo válido de terreno y desea realizar el valor de la venta de dicho terreno sin coste inicial para usted, podemos ayudarle.",
    "q_9_h": "¿Cuánto tardará la venta de mi terreno?",
    "q_9_p_1": "Hay muchos factores que afectan el tiempo que el proceso tomará. Estos incluyen, como se menciona en la respuesta anterior, alcanzar una masa crítica de parcelas dentro de un área para asegurar la viabilidad económica de ese proyecto.",
    "q_9_p_2": "Una vez que estemos listos para avanzar y asegurar la financiación, nuestro objetivo es establecer un título limpio en un plazo de 3-4 años. Una vez establecido el título limpio, trabajamos con todas las partes para maximizar el valor de ese terreno antes de su venta – un proceso que esperamos que se logre entre 12 y 24 meses.",
    "q_12_h": "¿Qué garantías tengo?",
    "q_12_p_1": "Como parte del contrato Locksmith, el inversor le garantizará un Primer Pago cuando se establezca un título limpio. Este Primer Pago reflejará el riesgo que el inversor está dispuesto a asumir y, por lo tanto, será significativamente inferior al precio previsto de venta.",
    "q_12_p_2": "Una vez que se haya establecido el título limpio, trabajaremos para maximizar el valor de ese terreno como se explicó anteriormente. Este proceso llevará 1-2 años dependiendo de la complejidad. Una vez vendido y según el contrato, recibirá un pago adicional, elevando su participación total en los ingresos totales a por lo menos 80 % de los ingresos netos de la venta.",
    "list_all": "LISTA DE TODAS LAS PREGUNTAS FRECUENTES"
  }
}
//</i18n>

export default {
  name: "FAQ",
  data() {
    return {
      t: t[this.$lang],
      accordion_active: null,

      faq_content: []
    };
  },
  mounted() {
    const vm = this;

    vm.faq_content = [
      {
        active: false,
        h: vm.t.q_4_h,
        p: [vm.t.q_4_p_1]
      },
      {
        active: false,
        h: vm.t.q_9_h,
        p: [vm.t.q_9_p_1, vm.t.q_9_p_2]
      },
      {
        active: false,
        h: vm.t.q_12_h,
        p: [vm.t.q_12_p_1, vm.t.q_12_p_2]
      }
    ];
  }
};
</script>

<style scoped>
.root_cont {
  background-color: rgb(4, 43, 93);
  padding: 60px 0;
  color: #fffff3;
}

h1 {
  text-align: center;
}

.acc_card {
  border-bottom: solid rgba(255, 255, 243, 0.3) 1px;
}

.acc_head {
  cursor: pointer;
  padding: 20px 0;
}

h5 {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 1.32rem!important;
  font-weight: 400!important;
}

.side_arrow_cont {
  pointer-events: none;
  position: relative;
}

.side_arrow {
  width: 30px;
  transition: 500ms;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  height: max-content;
}

.side_arrow.active {
  transform: rotateZ(-180deg);
}

.acc_body {
  overflow-y: hidden;

  height: auto;
  max-height: 0;
  transition: 1000ms;
}

.acc_body.active {
  max-height: 700px;
}

button.list_all {
  width: 260px;
  max-width: 80%;
  background-color: transparent;
  border-color: #fffff3;
  color: #fffff3;
  vertical-align: bottom;
}

button:hover,
button:focus {
  background-color: #fffff3;
  color: rgb(4, 43, 93);
}
</style>
