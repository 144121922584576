<template>
  <div class="root_cont">
    <div class="container">
      <h2 class="text-center">
        <span v-html="t.head"></span>
      </h2>

      <div class="mt-4 mb-4">
        <div class="row">
          <div class="col-md-2 col-sm-12">
            <img class="img" src="/static/images/TXsearch.svg" alt="" />
          </div>
          <div class="col-md-10 col-sm-12">
            <h5 v-html="t.h_1"></h5>
            <p v-html="t.p_1"></p>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="row">
          <div class="col-md-2 col-sm-12">
            <img
              src="/static/images/TXassess.svg"
              class="img assess_ta"
              alt=""
            />
          </div>
          <div class="col-md-10 col-sm-12">
            <h5 v-html="t.h_2"></h5>
            <p v-html="t.p_2"></p>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="row">
          <div class="col-md-2 col-sm-12">
            <svg-locksmith-icon class="img"></svg-locksmith-icon>
          </div>
          <div class="col-md-10 col-sm-12">
            <h5 v-html="t.h_3"></h5>
            <h6 v-html="t.p_3_1"></h6>
            <p v-html="t.p_3_2"></p>
            <h6 v-html="t.p_3_3"></h6>
            <p v-html="t.p_3_4"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head": "THE LOCKSMITH PROCESS",
    "h_1": "STEP 1 - SEARCH",
    "p_1": "Start your journey by registering an account and using the Terra Adriatica Platform to search for land to which you may have a valid claim.",
    "h_2": "STEP 2 - ASSESS",
    "p_2": "We will correlate the information you submit with millions of public records and apply our proprietary algorithms to generate a preliminary summary of land to which you may have a claim.",
    "h_3": "STEP 3 - REALISE VALUE",
    "p_3_1": "Either:",
    "p_3_2": "Enter, if eligible, into a fully transparent, Locksmith contract to obtain clean title ahead of the sale of that land at the highest possible value and at no upfront cost to yourself.",
    "p_3_3": "Or:",
    "p_3_4": "Simply maintain an active account with Terra Adriatica to be automatically advised of any change to your prospects and potential opportunities in the future regarding the land in question. Should you wish in the meantime to seek your own legal advice, or pursue your own claim at your expense, you can purchase a more detailed report to pass on."
  },
  "hr": {
    "head": "LOCKSMITH",
    "h_1": "1. KORAK - PRETRAŽI",
    "p_1": "Započnite svoje putovanje registracijom na našem portalu i, koristeći Terra Adriatica platformu,  potražite zemljišta na koja imate potencijalna vlasnička prava.",
    "h_2": "2. KORAK - PROCIJENI",
    "p_2": "Primjenom algoritama koje smo razvili, Vaše podatke povezat ćemo s milijunima javnih zapisa da bi dobili preliminarni prikaz zemljišta na koja imate moguća vlasnička prava.",
    "h_3": "3. KORAK - OSTVARI VRIJEDNOST",
    "p_3_1": "Ili",
    "p_3_2": "Ugovorite <i>Locksmith</i>, potpuno transparentan ugovor, ako ispunjavate potrebne uvjete – njime ćemo uspostaviti čisto vlasništvo nad zemljištem a time i omogućiti njegovu prodaju prema najvišoj mogućoj cijeni. I sve to bez ikakvih troškova unaprijed.",
    "p_3_3": "Ili",
    "p_3_4": "Otvorite račun na Terra Adriatica Portalu i primajte obavijesti o promjenama Vašeg vlasničkog statusa. Želite li sami potražiti pravno mišljenje ili uspostaviti čisto vlasništvo o svom trošku, nudimo Vam kupnju detaljnijih izvješća."
  },
  "es": {
    "head": "EL PROCESO DE LOCKSMITH",
    "h_1": "PASO 1 – BUSCAR",
    "p_1": "Comience su pasaje registrando una cuenta y utilizando la plataforma de Terra Adriatica para buscar el terreno del que podría tener un reclamo válido.",
    "h_2": "PASO 2 – EVALUAR",
    "p_2": "Correlacionaremos la información que usted nos ha proporcionado con millones de registros públicos y aplicaremos nuestros algoritmos propietarios para generar un resumen preliminar del terreno del que podría tener un reclamo.",
    "h_3": "PASO 3 – REALIZAR VALOR",
    "p_3_1": "Opción 1:",
    "p_3_2": "Si es elegible, celebre Locksmith, un contrato totalmente transparente, para obtener un título limpio antes de la venta de ese terreno al mayor valor posible y sin coste inicial para usted.",
    "p_3_3": "Opción 2:",
    "p_3_4": "Simplemente mantenga una cuenta activa en Terra Adriatica para ser automáticamente informado en el futuro sobre cualquier cambio de sus perspectivas y posibles oportunidades con respecto al terreno en cuestión. Mientras tanto, si desea buscar su propio asesoramiento jurídico o realizar su reclamo a sus expensas, puede comprar un informe más detallado que le puede ayudar."
  }
}
//</i18n>

export default {
  name: "LocksmithProcess",
  components: {},
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
  background-color: rgb(4, 43, 93);
  padding: 40px 0;
  color: #fffff3;
}

.img {
  width: 60px;
  margin-left: auto;
  display: block;
}

.img.assess_ta {
  width: 52px;
}

h5 {
  font-weight: 800 !important;
}

@media only screen and (max-width: 768px) {
  .img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 60px;
  }

  .img.assess_ta {
    width: 52px;
  }
}
</style>
