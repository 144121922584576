<template>
  <div>
    <div class="root_cont blue_bg">
      <div class="container">
        <div class="row">
          <h4
            class="fw-400 text-center"
            v-if="$store.getters['responsive/isWindowStateLessThen']('md')"
          >
            <span v-html="t.text_1"> </span>
          </h4>

          <div class="col-12 col-md-7">
            <div class="chart-cont">
              <DeepNeuralNetworksChart
                :active="in_viewport"
              ></DeepNeuralNetworksChart>
            </div>
          </div>

          <div class="col-12 col-md-5">
            <h4
              class="fw-400"
              style="margin-top: 10%; margin-bottom: 20px"
              v-if="$store.getters['responsive/isWindowStateMoreThen']('md')"
            >
              <span v-html="t.text_1"></span>
            </h4>
            <button
              class="btn btn-lg button_slide"
              @click="$router.push({ name: 'technology' })"
            >
              <span v-html="t.see_how"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "text_1": "We use Artificial Intelligence and Process Automation to solve complexity.",
    "see_how": "SEE HOW"
  },
  "hr": {
    "text_1": "Koristimo umjetnu inteligenciju i automatizaciju procesa za rješavanje složenih problema.",
    "see_how": "VIDI KAKO"
  },
  "es": {
    "text_1": "Utilizamos la inteligencia artificial y la automatización de procesos para resolver la complejidad.",
    "see_how": "VER CÓMO"
  }
}
//</i18n>

import "intersection-observer";
import DeepNeuralNetworksChart from "../../technology/charts/DeepNeuralNetworksChart";

export default {
  name: "HomeTechnologyMobile",
  components: { DeepNeuralNetworksChart },
  data() {
    return {
      t: t[this.$lang],

      in_viewport: true
    };
  },
  mounted() {
    const vm = this;

    {
      const io_options = {
        rootMargin: "0px",
        threshold: 0
      };

      const io_callback = entries => {
        vm.in_viewport = !!entries[0].isIntersecting;
      };

      let io_observer = new IntersectionObserver(io_callback, io_options);
      io_observer.observe(vm.$el);
    }
  }
};
</script>

<style scoped>
.root_cont {
  padding: 40px 0;
}

.btn {
  border-color: #fffff3;
  color: #fffff3;
  margin-top: 40px;
  width: 260px;
  max-width: 80%;
}

.chart-cont {
  height: 350px;
  margin-left: -40px;
  margin-right: -40px;
  overflow: hidden;
}

.chart-cont svg {
  transform: scale(1.1);
  height: 100%;
}

h4 {
  line-height: 150%;
}

@media (max-width: 576px) {
  .chart-cont {
    height: 390px;
    width: 390px;
    max-width: 100%;
    max-height: 90vw;
    margin: 0 auto;
  }

  .chart-cont svg {
    transform: scale(1);
    height: unset;
  }

  .btn {
    display: block;
    margin: 25px auto 0;
  }

  .text-center-mob {
    text-align: center;
  }
}
</style>
