<template>
  <div class="root_cont">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="card_ta">
            <div class="card_head">
              <svg-locksmith-icon class="img"></svg-locksmith-icon>
            </div>
            <div class="card_body">
              <p>
                {{ t.p_1 }}
              </p>
            </div>
            <div class="card_footer">
              <button
                @click="$router.push({ name: 'locksmith' })"
                class="btn button_slide"
              >
                {{ t.learn_more }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div class="card_ta">
            <div class="card_head">
              <img class="img" src="/static/images/AI.svg" alt="" />
            </div>
            <div class="card_body">
              <p>
                {{ t.p_2 }}
              </p>
            </div>
            <div class="card_footer">
              <button
                @click="$router.push({ name: 'technology' })"
                class="btn button_slide"
              >
                {{ t.learn_more }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div class="card_ta">
            <div class="card_head">
              <img class="img" src="/static/images/Team.svg" alt="" />
            </div>
            <div class="card_body">
              <p>
                {{ t.p_3 }}
              </p>
            </div>
            <div class="card_footer">
              <button
                @click="$router.push({ name: 'about' })"
                class="btn button_slide"
              >
                {{ t.learn_more }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "p_1": "Learn how we can work on your behalf to generate income from a valid claim to land.",
    "p_2": "See how we use Artificial Intelligence and Process Automation to solve complexity.",
    "p_3": "Meet the team and see what drives us.",
    "learn_more": "LEARN MORE"
  },
  "hr": {
    "p_1": "Saznajte kako Vam možemo pomoći ostvariti prihod od valjanog prava na zemljište.",
    "p_2": "Pogledajte kako koristimo umjetnu inteligenciju i automatizaciju procesa za rješavanje složenih problema.",
    "p_3": "Upoznajte naš tim i otkrijte što nas pokreće.",
    "learn_more": "SAZNAJ VIŠE"
  },
  "es": {
    "p_1": "Aprenda cómo podemos trabajar en su nombre para generar ingresos de un reclamo válido de terreno.",
    "p_2": "Vea cómo utilizamos la inteligencia artificial y la automatización de procesos para resolver la complejidad.",
    "p_3": "Conozca al equipo y vea qué nos impulsa.",
    "learn_more": "MÁS INFORMACIÓN"
  }
}
//</i18n>

export default {
  name: "ThreeCards",
  components: {},
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
  background-color: #fffff3;
  padding: 60px 0;
}

.card_ta {
  height: 310px;
  width: 90%;
  background-color: rgb(4, 43, 93);
  text-align: center;
  color: #fffff3;
  position: relative;
  margin: auto;
}

.card_ta p {
  font-weight: 600;
  padding: 0 20px;
  position: absolute;
  height: max-content;
  margin: auto;
  top: 0;
  bottom: 0;
}

.img {
  display: inline-block;
  height: 100%;
  padding: 15px 15px 0 15px;
}

button {
  padding: 10px;
  width: 75%;
  background-color: transparent;
  border-color: #fffff3;
  color: #fffff3;
  font-weight: 600;
  vertical-align: bottom;
}

.card_head {
  height: 25%;
}

.card_body {
  height: 50%;
  position: relative;
}

.card_footer {
  height: 25%;
  padding-top: 5%;
}

@media only screen and (max-width: 768px) {
  .card_ta {
    margin-bottom: 20px;
  }
}
</style>
