<template>
  <div>
    <div class="root_cont blue_bg">
      <HomeHeaderAnimationMobile></HomeHeaderAnimationMobile>
      <div class="container">
        <div class="main_text">
          <img class="stay_home" width="200" :src="$lang === 'hr' ? '/static/images/OstanimoOdogovorni.svg' : '/static/images/StaySafe.svg'" alt="">
          <div class="v-med-1">
            <h3 class="fw-400 mt-3" ref="text_cont_1"></h3>
          </div>
          <div ref="text_cont_2" class="v-med-2">
            <h1>
              <span v-html="t.text_2_h"></span>
            </h1>
            <h3 class="fw-400">
              <span v-html="t.text_2_p"></span>
            </h3>
            <div class="btn-cont">
              <button
                class="btn btn-lg button_slide_white mt-4"
                @click="toRegistration()"
              >
                <span v-html="t.REGISTER"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeaderAnimationMobile from "./HomeHeaderAnimationMobile";
const t =
  //<i18n>
{
  "en": {
    "text_1": "We enable owners of land in Croatia with unclean title to realize value from its sale - at no upfront cost or risk",
    "text_2_h": "YOUR FREE REPORT",
    "text_2_p": "Could you have an ancestral claim to land in Croatia? Register for your free personalized report.",
    "REGISTER": "REGISTER"
  },
  "hr": {
    "text_1": "Omogućujemo vlasnicima zemljišta u Hrvatskoj s neriješenim vlasničkim pravima ostvariti vrijednost od prodaje - bez troškova unaprijed i bez rizika",
    "text_2_h": "VAŠE BESPLATNO IZVJEŠĆE",
    "text_2_p": "Jeste li naslijedili pravo na zemljište u Hrvatskoj? Registrirajte se za besplatno personalizirano izvješće.",
    "REGISTER": "REGISTRACIJA"
  },
  "es": {
    "text_1": "Les permitimos a los propietarios con un reclamo válido de terreno sin título limpio realizar el valor de la venta de ese terreno, sin coste o riesgo inicial.",
    "text_2_h": "SU INFORME GRATUITO",
    "text_2_p": "Tiene un reclamo válido de terreno en Croacia? Regístrese para el informe personalizado gratuito.",
    "REGISTER": "REGÍSTRESE"
  }
}
//</i18n>

import { TweenMax, TimelineMax, Linear } from "gsap/all";

export default {
  name: "HomeHeaderMobile",
  components: { HomeHeaderAnimationMobile },
  data() {
    return {
      t: t[this.$lang]
    };
  },
  mounted() {
    const vm = this;

    const AnimateTyping = class {
      constructor(text_cont, text) {
        const CI = this;

        CI.text_cont = text_cont;
        CI.text = text;

        CI.typing_duration = CI.text.length / 40;
        CI.typing_delay = 1;

        CI.prepare();
      }
      prepare() {
        const CI = this;

        CI.text_span = CI.text_cont.appendChild(document.createElement("span"));
        CI.cursor_span = CI.text_cont.appendChild(
          document.createElement("span")
        );

        const font_size = parseFloat(
          window.getComputedStyle(CI.text_cont).fontSize
        );

        TweenMax.set(CI.cursor_span, {
          display: "inline-block",
          width: font_size * 0.55,
          height: font_size,
          position: "relative",
          "margin-left": 0,
          "margin-bottom": -font_size * 0.1,
          "background-color": "#fff"
        });
      }
      run() {
        const CI = this;

        const cursor_tl = new TimelineMax({ repeat: 2, delay: 0 });
        cursor_tl.to(CI.cursor_span, 0.1, { alpha: 0, delay: 0.5 });
        cursor_tl.to(CI.cursor_span, 0.1, { alpha: 1, delay: 0.5 });

        let typing_tween;
        {
          const proxy = { text_len: 0 };
          typing_tween = TweenMax.to(proxy, CI.typing_duration, {
            text_len: CI.text.length,
            ease: Linear.easeNone,
            delay: CI.typing_delay,
            onUpdate: function() {
              try {
                CI.text_span.textContent = CI.text.slice(0, proxy.text_len);
              } catch (e) {}
            }
          });
        }

        typing_tween.eventCallback("onStart", () => {
          cursor_tl.pause(0);
        });
        typing_tween.eventCallback("onComplete", () => {
          cursor_tl.play();
        });
      }
    };

    const animateTyping = new AnimateTyping(vm.$refs.text_cont_1, vm.t.text_1);
    const isPrerendering = window.location.href.indexOf("localhost:8001") !== -1

    if (!isPrerendering)
      animateTyping.run();

    const typingDuration =
      animateTyping.typing_delay + animateTyping.typing_duration + 2;

    TweenMax.to(vm.$refs.text_cont_1, 1.5, {
      alpha: 0,
      delay: typingDuration
    });

    TweenMax.from(vm.$refs.text_cont_2.querySelector("h1"), 2, {
      y: -20,
      alpha: 0,
      delay: typingDuration + 1
    });
    TweenMax.from(vm.$refs.text_cont_2.querySelector("h3"), 2, {
      x: -20,
      alpha: 0,
      delay: typingDuration + 1
    });
    TweenMax.from(vm.$refs.text_cont_2.querySelector(".btn-cont"), 2, {
      y: 20,
      alpha: 0,
      delay: typingDuration + 1
    });
    TweenMax.from(vm.$el.querySelector(".stay_home"), 2, {
      y: 20,
      alpha: 0,
      delay: typingDuration + 2.5
    });
  },
  methods: {
    toRegistration() {
      this.$store.dispatch("auth/openAuth", 2);
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 54px;
}

h3 {
  font-size: 28px;
  line-height: 160%;
}

.root_cont {
  height: 505px;
  position: relative;
}

.main_text {
  position: relative;
  text-align: center;
  z-index: 2;
  width: 68%;
  margin: auto;
}

.v-med-1 {
  position: absolute;
  top: 0;
  margin-top: 22%;
}

.v-med-2 {
  position: absolute;
  top: 0;
  margin-top: 18%;
}

.btn.button_slide_white {
  border-color: #fffff3;
  color: #fffff3;
  width: 44%;
}
.stay_home {
  margin-top: 1.6rem;
  width: unset;
  height: 42px;
}
@media (max-width: 576px) {
  .stay_home {
    margin-top: 1rem;
    height: 35px;
  }
  h1 {
    font-size: 34px;
  }

  h3 {
    font-size: 24px;
  }

  .root_cont {
    height: 390px;
  }

  .main_text {
    width: 100%;
  }

  .v-med-1,
  .v-med-2 {
    margin-top: 18%;
  }

  .btn.button_slide_white {
    width: 80%;
  }
}
</style>
