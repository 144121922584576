<template>
  <div>
    <div class="root_cont yellow_bg">
      <div class="container">
        <h4
          class="fw-400 text-center"
          v-if="$store.getters['responsive/isWindowStateLessThen']('md')"
        >
          <span v-html="t.text_1"> </span>
        </h4>
        <div class="row">

          <div class="col-12 col-md-7 desktop-2">
            <div class="text-center mt-4 mb-3">
              <img
                style="width:92%"
                src="/static/images/TeamIlustration.svg"
                alt=""
              />
            </div>
          </div>

          <div class="col-12 col-md-5">
            <h4
              class="fw-400"
              style="margin-top: 10%"
              v-if="$store.getters['responsive/isWindowStateMoreThen']('md')"
            >
              <span v-html="t.text_1"> </span>
            </h4>
            <button
              class="btn btn-lg button_slide_blue"
              @click="$router.push({ name: 'about' })"
            >
              <span v-html="t.say_hello"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "text_1": "Meet the team and see what drives us.",
    "say_hello": "SAY HELLO"
  },
  "hr": {
    "text_1": "Upoznajte naš tim i otkrijte što nas pokreće.",
    "say_hello": "POZDRAVI NAS"
  },
  "es": {
    "text_1": "Conozca al equipo y vea qué nos impulsa.",
    "say_hello": "SALUDENOS"
  }
}
//</i18n>

export default {
  name: "HomeAboutMobile",
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
  padding: 40px 0;
}
.btn {
  border-color: rgb(4, 43, 93);
  color: rgb(4, 43, 93);
  width: 260px;
  max-width: 80%;
  margin-top: 40px;
}

.desktop-2 {
  order: 2;
}

h4 {
  line-height: 150%;
}
@media (max-width: 768px) {
  .desktop-2 {
    order: unset;
  }
  .btn {
    display: block;
    margin: 25px auto 0;
  }
}


</style>
