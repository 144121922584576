<template>
  <div>
    <div class="root_cont blue_bg">
      <div class="container">
        <div class="main_text">
          <div class="text-center">
            <h3>{{ t.h1 }}</h3>
            <h4>{{ t.h2 }}</h4>
            <span class="btn button_slide_white" @click="opened = !opened">{{ t.more }}</span>
          </div>


          <div :class="['text', opened ? 'opened' : '']">
            <p v-for="i in 18" v-html="t['p'+i]"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    more: 'More',
    h1: "UPDATE!",
    h2: "We never said it was going to be easy!",

    p1: "We’d like to use this opportunity to give you an update on the current \n" +
        "status of Terra Adriatica, and to explain why we’re pressing pause on our \n" +
        "pilot Locksmith Project.",

    p2: "From the moment we launched www.terraadriatica.hr, our mission was to \n" +
        "return productivity to vast tracks of Croatian land with unclean legal title.",

    p3: "We identified and engaged with thousands of individuals around the world\n" +
        "who wished to establish a valid claim for land or searched for help \n" +
        "with land title issues in the hope they might realize value from its sale. \n",

    p4: "We have acquired over 7,500 registered users globally; delivered \n" +
        "thousands of free reports, and identified possible matches to over \n" +
        "2m land parcels representing an aggregate 4.4 billion square meters.\n",
    p5:
        "We believe that the quality of our work has been widely recognised in \n" +
        "Croatia and beyond.\n",
    p6:
        "We employed some of the smartest people in the country to develop \n" +
        "ground-breaking technology to track down and map potential owners of \n" +
        "land, despite the severe shortcomings in the land registry. \n",
    p7:
        "As a result, tens of thousands of individuals of Croatian descent globally \n" +
        "have used our platform to perform searches for land resulting in 4.4 billion\n" +
        "square meters identified as a potential match.  \n",
    p8:
        "A great deal went on to sign up to our Locksmith Project to seek titular \n" +
        "claim over 383km2 of land – equivalent to the area of the island of Brač.\n",
    p9:
        "We developed a financial solution that in theory provides the financing \n" +
        "needed to obtain clean title at no upfront cost to the claimant from \n" +
        "investors. \n",
    p10:
        "Herein lies our most significant challenge. Putting aside the impact that \n" +
        "Covid and the conflict in Ukraine has had on investment funds, the land \n" +
        "we identified for our first project was very spread out. \n",
    p11:
        "The more dispersed the land, the more difficult it is to fund given the \n" +
        "engagement needed at a local level with government, the courts and of \n" +
        "course the individuals. A project with land focused in one area would \n" +
        "provide economies of scale.",
    p12: "Our intention remains to achieve the goal we set in the very beginning – \n" +
        "to bring billions of square meters of dormant land in Croatia to \n" +
        "productivity.\n",
    p13:
        "Our communication and research among TA community opened us to new\n" +
        "perspectives and we hope, may pave the way to offer a new type of \n" +
        "service.\n",
    p14:
        "Your TA profile and the agreement remain active, we are safekeeping the \n" +
        "documentation and collected data from your Locksmith Agreement on our \n" +
        "servers and are still here for you to communicate on whatever matter \n" +
        "emerges in the meantime. \n",
    p15:
        "If you have signed a Locksmith Agreement and our pause causes you to \n" +
        "worry or if there are other concerns you may have, please feel free to \n" +
        "contact us as even in our “pause mode” we will maintain some service. \n",
    p16:
        "Your understanding is appreciated, and the TA team is thankful for your \n" +
        "support.\n",
    p17:
        "We will be sending further news by e-mail, both the general updates and \n" +
        "news related to individual cases.\n",
    p18:
        "Our website, and in particular our <a href='/en/faq'>FAQ</a> page, provides answers to many of \n" +
        "the questions we get asked. If you require any further information, please \n" +
        "do not hesitate to contact usme on hello@terraadriatica.hr."
  },
  "hr": {
    more: 'Više',
    h1: "AŽURIRANJE!",
    h2: "Nismo nikada niti rekli da će biti lako!",

    p1: "Željeli bismo iskoristiti ovu priliku kako bismo vam dali najnovije \n" +
        "informacije o trenutnom statusu Terra Adriatice i objasnili zbog čega \n" +
        "pauziramo sa našim Locksmith pilot projektom.",

    p2: "Od trenutka pokretanja naše web stranice www.terraadriatica.hr, naša je \n" +
        "misija bila vratiti u produktivnost milijune kvadratnih metara hrvatskih \n" +
        "zemljišta sa nečistim pravnim naslovom.",

    p3: "Identificirali smo i surađivali sa tisućama pojedinaca diljem svijeta koji su \n" +
        "željeli uspostaviti valjano pravo nad zemljištem ili su tražili pomoć oko \n" +
        "pitanja vlasništva nad zemljištem u nadi da će uspjeti ostvariti vrijednost \n" +
        "od njegove prodaje.",

    p4: "Stekli smo preko 7.500 registriranih korisnika diljem svijeta: isporučili \n" +
        "tisuće besplatnih izvješća i identificirali moguća podudaranja sa preko 2 \n" +
        "milijuna zemljišnih parcela koje predstavljaju ukupno 4,4 milijarde \n" +
        "četvornih metara.",
    p5:
        "Vjerujemo da je kvaliteta našeg rada nadaleko prepoznata u Hrvatskoj i \n" +
        "šire.",
    p6:
        "Zaposlili smo neke od najkompetentnijih ljudi u zemlji kako bi nam pomogli\n" +
        "razviti revolucionarnu tehnologiju za pronalaženje i mapiranje potencijalnih\n" +
        "vlasnika zemljišta, unatoč ozbiljnim nedostacima u zemljišnim knjigama.",
    p7:
        "Kao rezultat toga, deseci tisuća pojedinaca hrvatskog podrijetla diljem \n" +
        "svijeta koristili su našu platformu za traženje zemljišta što je rezultiralo sa \n" +
        "4,4 milijarde četvornih metara identificiranih kao potencijalno \n" +
        "podudaranje.",
    p8:
        "Većina ih je upravo prijavljena za naš Locksmith projekt kako bi se \n" +
        "zatražilo pravo vlasništva nad više od 383km2 zemljišta – što je ekvivalent \n" +
        "području otoka Brača.",
    p9:
        "Razvili smo financijsko rješenje koje uz pomoć naših investitora, u teoriji \n" +
        "osigurava financiranje potrebno za dobivanje čistog naslova bez plaćanja \n" +
        "unaprijed za podnositelja zahtjeva.",
    p10:
        "Ovdje leži naš najznačajniji izazov. Ostavljajući po strani utjecaj koji su \n" +
        "Covid i sukob u Ukrajini imali na investicijske fondove, zemljište koje smo \n" +
        "identificirali za naš prvi projekt bilo je vrlo raspršeno.",
    p11:
        "Što je zemljište više raspršeno, to ga je teže financirati s obzirom na \n" +
        "angažman koji je potreban na lokalnoj razini sa vlastima, sudovima i \n" +
        "naravno pojedincima. Projekt sa zemljištem usmjerenim na jedno područje \n" +
        "osigurao bi ekonomiju razmjera.",
    p12:
        "Ostaje nam namjera ostvariti cilj koji smo si zadali na samom početku – \n" +
        "milijarde četvornih metara neaktivnog zemljišta u Hrvatskoj dovesti do \n" +
        "produktivnosti.",
    p13:
        "Naša komunikacija i istraživanje među TA zajednicom otvorila nam je nove\n" +
        "perspektive i nadamo se da će otvoriti put ponudi neke nove vrste usluge.",
    p14:
        "Vaš TA profil i ugovor ostaju aktivni, čuvamo dokumentaciju i prikupljene \n" +
        "podatke iz vašeg Locksmith ugovora na našim poslužiteljima i još uvijek \n" +
        "smo tu za komunikaciju o bilo čemu što se pojavi u međuvremenu.",
    p15:
        "Ukoliko ste potpisali Locksmith ugovor, a naša pauza u radu izaziva \n" +
        "zabrinutost ili ukoliko imate drugih nedoumica, slobodno nas kontaktirajte \n" +
        "jer ćemo čak i u našem „pauziranom načinu rada“ održavati neke usluge.",
    p16:
        "Cijenimo vaše razumijevanje, a TA tim je zahvalan na vašoj podršci.",
    p17:
        "Daljnje vijesti slati ćemo e-poštom, kako opća ažuriranja tako i vijesti \n" +
        "vezane za pojedinačne slučajeve.",
    p18:
        "Naša web stranica, a posebno naša <a href='/hr/faq'>stranica sa čestim pitanjima</a>, pruža \n" +
        "odgovore na mnoga pitanja koja nam se postavljaju. Ukoliko trebate \n" +
        "dodatne informacije, slobodno nas kontaktirajte na \n" +
        "hello@terraadriatica.hr."
  },
  "es": {

  }
}
//</i18n>


export default {
  name: "HomeHeaderUpdate",
  data() {
    return {
      t: t[this.$lang],

      opened: false
    };
  },
  mounted() {
    this.opened = this.$route.query && this.$route.query.update == '1'
  },
  watch: {
    "$store.state.core.modal_unavailable_open"() {
      setTimeout(() => {
        if (this.$route.query && this.$route.query.update == '1') {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.opened = true;
        }
      }, 500)
    }
  }
};
</script>

<style scoped lang="scss">
.root_cont {
  position: relative;
}

h3, h4 {
  font-weight: 400;
}

.main_text {
  margin: 40px 0;
}

.btn.button_slide_white {
  border-color: #fffff3;
  color: #fffff3;
  width: 44%;
  margin-top: 15px;
  margin-bottom: 20px;
}

.text {
  height: 0;
  overflow: hidden;
  transition: 1500ms;

  &.opened {
    height: auto;
  }

  &:first-child {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {


}
</style>
