<template>
  <svg class="metro_chart"></svg>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "rights_checking": "rights checking",
    "cadastre": "cadastre",
    "land_register": "land register",
    "lawyer": "lawyer",
    "geodesist": "geodesist",
    "investors": "investors",
    "recordation": "recordation",
    "clean_title": "clean title",
    "seller": "seller",
    "co_owners": "co-owners",
    "selling": "selling",
    "market_price": "market price",
    "PRECONTRACT": "PRECONTRACT",
    "JOINT_VENTURE": "JOINT VENTURE",
    "PAYMENT": "PAYMENT",
    "you": "YOU"
  },
  "hr": {
    "rights_checking": "provjera prava",
    "cadastre": "katastar",
    "land_register": "zemljišne knjige",
    "lawyer": "odvjetnik",
    "geodesist": "geodet",
    "investors": "investitori",
    "recordation": "uknjižba",
    "clean_title": "čisto vlasništvo",
    "seller": "prodavatelj",
    "co_owners": "suvlasnici",
    "selling": "prodaja",
    "market_price": "tržišna cijena",
    "PRECONTRACT": "PREDUGOVOR",
    "JOINT_VENTURE": "ZAJEDNIČKI POTHVAT",
    "PAYMENT": "ISPLATA",
    "you": "VI"
  },
  "es": {
    "rights_checking": "comprobación de derechos",
    "cadastre": "catastro",
    "land_register": "registro de la propiedad",
    "lawyer": "abogado",
    "geodesist": "agrimensor",
    "investors": "inversores",
    "recordation": "inscripción",
    "clean_title": "título limpio",
    "seller": "vendedor",
    "co_owners": "copropietarios",
    "selling": "venta",
    "market_price": "precio de mercado",
    "PRECONTRACT": "PRECONTRATO",
    "JOINT_VENTURE": "EMPRESA CONJUNTA",
    "PAYMENT": "PAGO",
    "you": "USTED"
  }
}
//</i18n>

import * as d3 from "d3";

export default {
  name: "MetroChart",
  props: ["metro_animation_time"],
  data() {
    return {
      t: t[this.$lang]
    };
  },
  mounted() {
    const vm = this;

    class MetroChart {
      constructor() {
        const CI = this;

        CI.selection = null;
        CI.svg = {};
        CI.focus = {};
        CI.svg_group = {};
        CI.margin = {};
        CI.width = 0;
        CI.height = 0;

        CI.d3_x = {}; // changing x domain to scaleBand
        CI.d3_y = {};

        CI.duration = vm.metro_animation_time;

        CI.data_stash = [];

        CI.data_len = 0;
      }

      loadElements() {
        const CI = this;
        //// set dimensions
        CI.svg = d3.select(".metro_chart");
        CI.full_width =
          +CI.svg.node().clientWidth || +CI.svg.node().parentNode.clientWidth;
        CI.full_height =
          +CI.svg.node().clientHeight || +CI.svg.node().parentNode.clientHeight;

        const aspect_ratio = CI.full_width / CI.full_height;

        if (CI.full_width < 1000) {
          CI.full_width = 1000;
          CI.full_height = CI.full_width / aspect_ratio;
        }

        CI.svg
          .attr("viewBox", "0 0 " + CI.full_width + " " + CI.full_height)
          .attr("preserveAspectRatio", "xMidYMid meet");

        CI.margin = {
          top: 50,
          right: 110,
          bottom: 60,
          left: 110
        };
        CI.width = CI.full_width - CI.margin.left - CI.margin.right;
        CI.height = CI.full_height - CI.margin.top - CI.margin.bottom;

        CI.d3_x = d3
          .scaleLinear()
          .range([0, CI.width])
          .domain([0, 200]); // changing x domain to scaleBand
        CI.d3_y = d3
          .scaleLinear()
          .range([CI.height, 0])
          .domain([0, 100]);

        CI.focus = CI.svg
          .append("g")
          .attr("class", "focus")
          .attr(
            "transform",
            "translate(" + CI.margin.left + "," + CI.margin.top + ")"
          );

        CI.svg_group = CI.focus.append("g").classed("svg_group", true);

        CI.group_lvl_1 = CI.svg_group.append("g");

        CI.group_lvl_2 = CI.svg_group.append("g");

        CI.group_lvl_3 = CI.svg_group.append("g");

        CI.group_lvl_4 = CI.svg_group.append("g");

        CI.group_lvl_5 = CI.svg_group.append("g");

        CI.d3_path = d3
          .line()
          .curve(d3.curveCatmullRom.alpha(0.5))
          .x(d => CI.d3_x(d[0]))
          .y(d => CI.d3_y(d[1]));
      }

      setupData() {
        const CI = this;

        CI.points = {};
        CI.points.ta = [
          [0, 50, ""],
          [20, 0, vm.t.rights_checking],
          [20, 100, vm.t.cadastre],
          [45, 25, vm.t.land_register],
          [55, 70, vm.t.lawyer],
          [80, 100, vm.t.geodesist],
          [80, 0, vm.t.investors],
          [100, 50, ""],
          [120, 0, vm.t.recordation],
          [120, 100, vm.t.clean_title],
          [130, 35, vm.t.seller],
          [135, 20, vm.t.co_owners],
          [145, 75, vm.t.selling],
          [180, 0, vm.t.co_owners],
          [180, 100, vm.t.market_price],
          [200, 50, ""]
        ];

        CI.points.vendor = [
          [0, 50, vm.t.PRECONTRACT],
          [100, 50, vm.t.JOINT_VENTURE],
          [200, 50, vm.t.PAYMENT]
        ];

        const p = CI.points.ta;

        CI.ta_path_1 = CI.points.ta;

        CI.ta_path_2 = JSON.parse(JSON.stringify(CI.points.ta));

        CI.ta_path_2 = [
          CI.ta_path_2[0],
          ...d3.shuffle(CI.ta_path_2.slice(1, 7)),
          CI.ta_path_2[7],
          ...d3.shuffle(CI.ta_path_2.slice(8, 15)),
          CI.ta_path_2[15]
        ];
      }

      initial() {
        const CI = this;

        {
          CI.points.ta.forEach(d => {
            const node = CI.group_lvl_2
              .append("g")
              .attr("class", "ta_static")
              .attr(
                "transform",
                "translate(" + CI.d3_x(d[0]) + ", " + CI.d3_y(d[1]) + ")"
              );

            node
              .append("circle")
              .attr("class", "outer")
              .attr("r", 20)
              .style("fill", "#fffff3")
              .style("stroke", "#c09261")
              .style("stroke-width", "6px");

            node
              .append("circle")
              .attr("class", "inner")
              .style("fill", "#c09261")
              .attr("r", 13);

            let text_pos = [0, 0];
            let text_anchor = "left";

            if (d[1] > 50) {
              text_pos[0] = CI.d3_x(d[0]);
              text_pos[1] = CI.d3_y(d[1]) - 30;
              text_anchor = "middle";
            } else {
              text_pos[0] = CI.d3_x(d[0]);
              text_pos[1] = CI.d3_y(d[1]) + 47;
              text_anchor = "middle";
            }

            if (d[0] === 130 && d[1] === 35) {
              text_pos[0] = CI.d3_x(d[0]) - 30;
              text_pos[1] = CI.d3_y(d[1]) + 37;
            }

            const text = CI.group_lvl_5
              .append("text")
              .attr("class", "ta_static")
              .attr("x", text_pos[0])
              .attr("y", text_pos[1])
              .style("text-anchor", text_anchor)
              .style("fill", "rgb(4,43,93)")
              .style("font-size", "18px")
              .style("font-weight", 600)
              .text(d[2]);
          });
        }

        {
          CI.points.vendor.forEach((d, i) => {
            const node = CI.group_lvl_4
              .append("g")
              .attr("class", "vendor_static")
              .attr(
                "transform",
                "translate(" + CI.d3_x(d[0]) + ", " + CI.d3_y(d[1]) + ")"
              );

            node
              .append("circle")
              .attr("class", "outer")
              .attr("r", 21)
              .style("fill", "#fffff3")
              .style("stroke", "lightgrey")
              .style("stroke-width", "9px");

            const text = CI.group_lvl_5
              .append("text")
              .attr("class", "vendor_static")
              .style("fill", "rgb(4,43,93)")
              .style("font-size", "24px")
              .style("font-weight", "600")
              .style("text-anchor", "middle")
              .attr("x", CI.d3_x(d[0]))
              .attr("y", CI.d3_y(d[1]) - 40)
              .text(d[2]);
          });
        }

        {
          CI.ta_circle_1 = CI.group_lvl_3
            .append("g")
            .attr(
              "transform",
              "translate(" +
                CI.d3_x(CI.points.ta[0][0]) +
                "," +
                CI.d3_y(CI.points.ta[0][1]) +
                ")"
            );

          CI.ta_circle_1
            .append("circle")
            .attr("r", 25)
            .style("fill", "#c09261");

          CI.ta_circle_1
            .append("text")
            .attr("y", 6)
            .style("text-anchor", "middle")
            .text("TA")
            .style("fill", "white")
            .style("font-size", "18px");

          CI.ta_circle_2 = CI.group_lvl_3
            .append("g")
            .attr(
              "transform",
              "translate(" +
                CI.d3_x(CI.points.ta[0][0]) +
                "," +
                CI.d3_y(CI.points.ta[0][1]) +
                ")"
            );

          CI.ta_circle_2
            .append("circle")
            .attr("r", 25)
            .style("fill", "#c09261");

          CI.ta_circle_2
            .append("text")
            .attr("y", 6)
            .style("text-anchor", "middle")
            .text("TA")
            .style("fill", "white")
            .style("font-size", "18px");

          CI.vendor_circle = CI.group_lvl_5
            .append("g")
            .attr(
              "transform",
              "translate(" +
                CI.d3_x(CI.points.vendor[0][0]) +
                "," +
                CI.d3_y(CI.points.vendor[0][1]) +
                ")"
            );

          CI.vendor_circle
            .append("circle")
            .attr("r", 26)
            .style("fill", "rgb(4,43,93)");

          CI.vendor_circle
            .append("text")
            .attr("y", 6)
            .style("text-anchor", "middle")
            .text(vm.t.you)
            .style("fill", "white")
            .style("font-size", "18px");
        }

        {
          CI.ta_line_1 = CI.group_lvl_1
            .append("path")
            .style("fill", "none")
            .style("stroke", "#c09261")
            .style("stroke-width", "6px")
            .attr("d", () => CI.d3_path(CI.ta_path_1));

          CI.ta_line_2 = CI.group_lvl_1
            .append("path")
            .style("fill", "none")
            .style("stroke", "#c09261")
            .style("stroke-width", "6px")
            .attr("d", () => CI.d3_path(CI.ta_path_2));

          const l1_len = CI.ta_line_1.node().getTotalLength();
          const l2_len = CI.ta_line_2.node().getTotalLength();

          CI.ta_line_1
            .attr("stroke-dasharray", l1_len + " " + l1_len)
            .attr("stroke-dashoffset", l1_len);

          CI.ta_line_2
            .attr("stroke-dasharray", l2_len + " " + l2_len)
            .attr("stroke-dashoffset", l2_len);
        }

        {
          CI.group_lvl_3
            .append("line")
            .attr("class", "vendor static")
            .style("stroke", "lightgrey")
            .style("stroke-width", "6px")
            .attr("x1", CI.d3_x(CI.points.vendor[0][0]))
            .attr("y1", CI.d3_y(CI.points.vendor[0][1]))
            .attr("x2", CI.d3_x(CI.points.vendor[2][0]))
            .attr("y2", CI.d3_y(CI.points.vendor[2][1]));

          CI.vendor_line = CI.group_lvl_3
            .append("line")
            .attr("class", "vendor dynamic")
            .style("stroke", "rgb(4,43,93)")
            .style("stroke-width", "9px")
            .attr("x1", CI.d3_x(CI.points.vendor[0][0]))
            .attr("y1", CI.d3_y(CI.points.vendor[0][1]))
            .attr("x2", CI.d3_x(CI.points.vendor[0][0]))
            .attr("y2", CI.d3_y(CI.points.vendor[0][1]));
        }
      }

      update() {
        const CI = this;

        {
          function translateAlong(path) {
            let l = path.getTotalLength();
            return function(d, i, a) {
              return function(t) {
                let p = path.getPointAtLength(t * l);
                return "translate(" + p.x + "," + p.y + ")";
              };
            };
          }

          CI.ta_circle_1
            .transition()
            .ease(d3.easeLinear)
            .duration(CI.duration)
            .attrTween("transform", translateAlong(CI.ta_line_1.node()));

          CI.ta_circle_2
            .transition()
            .ease(d3.easeLinear)
            .duration(CI.duration)
            .attrTween("transform", translateAlong(CI.ta_line_2.node()));

          CI.ta_line_1
            .transition()
            .duration(CI.duration)
            .ease(d3.easeLinear)
            .attr("stroke-dashoffset", 0);

          CI.ta_line_2
            .transition()
            .duration(CI.duration)
            .ease(d3.easeLinear)
            .attr("stroke-dashoffset", 0);
        }

        {
          CI.svg_group
            .selectAll("g.ta_static")
            .select("circle.inner")
            .style("opacity", 0)
            .transition()
            .delay((d, i) => CI.duration * (i / (CI.points.ta.length - 1)))
            .style("opacity", 1);
        }

        {
          CI.vendor_circle
            .transition()
            .ease(d3.easeLinear)
            .duration(CI.duration)
            .attr(
              "transform",
              "translate(" +
                CI.d3_x(CI.points.vendor[2][0]) +
                "," +
                CI.d3_y(CI.points.vendor[2][1]) +
                ")"
            );

          CI.svg_group
            .selectAll("g.vendor_static circle.outer")
            .transition()
            .duration(0)
            .delay(
              (d, i) => CI.duration / (i === 0 ? CI.duration : i === 1 ? 2 : 1)
            )
            .style("fill", "rgb(4,43,93)")
            .style("stroke", "rgb(4,43,93)");
        }

        {
          CI.vendor_line
            .transition()
            .ease(d3.easeLinear)
            .duration(CI.duration)
            .attr("x2", CI.d3_x(CI.points.vendor[2][0]))
            .attr("y2", CI.d3_y(CI.points.vendor[2][1]));
        }
      }
    }

    const metro = new MetroChart();
    metro.loadElements();
    metro.setupData();
    metro.initial();
    metro.update();

    d3.selectAll(".updateMetro").on("click", () => metro.update());
  }
};
</script>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
