<template>
  <div>
    <div>
      <HomeHeaderUpdate/>
      <Journey/>
      <HomeCounterCont/>
      <HomeSolutionMobile/>
      <HomeTechnologyMobile/>
      <HomeAboutMobile/>
      <FAQ/>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/Core/navbar/NavBar";
import Carousel from "../../components/web/home/Carousel";
import Journey from "../../components/web/home/Journey";
import LocksmithProcess from "../../components/web/home/LocksmithProcess";
import ThreeCards from "../../components/web/home/ThreeCards";
import FAQ from "../../components/web/home/FAQ";
import MetroChart from "../../components/web/home/MetroChart";
import HomeHeaderMobile from "../../components/web/home/mobile/HomeHeaderMobile";
import HomeSolutionMobile from "../../components/web/home/mobile/HomeSolutionMobile";
import HomeTechnologyMobile from "../../components/web/home/mobile/HomeTechnologyMobile";
import HomeAboutMobile from "../../components/web/home/mobile/HomeAboutMobile";
import HomeCounterCont from "../../components/web/home/HomeCounterCont"
import HomeHeaderUpdate from "../../components/web/home/HomeHeaderUpdate"

export default {
  name: "Home",
  components: {
    HomeHeaderUpdate,
    HomeCounterCont,
    HomeAboutMobile,
    HomeTechnologyMobile,
    HomeSolutionMobile,
    HomeHeaderMobile,
    MetroChart,
    FAQ,
    ThreeCards,
    LocksmithProcess,
    Journey,
    Carousel,
    NavBar
  }
};
</script>

<style scoped></style>
