<template>
  <div class="root_cont yellow_bg" id="start_your_journey">
    <div class="image_bg yellow-gradient mobile-show"></div>
    <div class="image_bg mobile-hide"></div>
    <div class="container">
      <h2 class="mb-3">{{ t.head }}</h2>
      <div class="under_head mb-4">
        <h5 class="fw-400">
          <span v-html="t.under_head_2"></span>
        </h5>
      </div>
      <JourneyAutoComplete
        :fetchResults="fetchResults"
        :run="startJourney"
      ></JourneyAutoComplete>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head": "START YOUR JOURNEY HERE",
    "under_head_1": "We enable owners with a valid claim to land without clean title to realize value from its sale - at no upfront cost.",
    "under_head_2": "Enter your family name below to discover what rights to land you may have."
  },
  "hr": {
    "head": "ZAPOČNITE SVOJE PUTOVANJE",
    "under_head_1": "Vlasnicima s legitimnim pravom na zemljišta koja nemaju riješeno vlasničko pitanje omogućujemo da ostvare vrijednost od prodaje tog zemljišta - bez troškova unaprijed.",
    "under_head_2": "Upišite svoje prezime i saznajte koja su Vaša moguća prava na zemljište."
  },
  "es": {
    "head": "COMIENCE SU PASAJE AQUÍ",
    "under_head_1": "Les permitimos a los propietarios con un reclamo válido de terreno sin título limpio realizar el valor de su venta, sin coste inicial.",
    "under_head_2": "Introduzca su apellido a continuación para descubrir qué derechos de terrenos podría tener."
  }
}
//</i18n>

import JourneyAutoComplete from "./JourneyAutoComplete";
export default {
  name: "Journey",
  components: { JourneyAutoComplete },
  data() {
    return {
      t: t[this.$lang]
    };
  },
  methods: {
    startJourney(surname_input) {
      return this.$store.dispatch('core/updateModalUnavailableOpen', true)
      const vm = this;
      if (!surname_input) return;

      vm.$router.push({ name: "journey", params: { surname: surname_input } });
    },
    fetchResults(surname_input, callback) {
      const vm = this;

      const BASE_URL = process.env.VUE_APP_BASEURL;
      let url =
        BASE_URL +
        "/api/journey?surname=" +
        surname_input +
        "&is_autocomplete=1";

      fetch(url)
        .then(response => response.json())
        .then(data => {
          callback(
            (data.results || data).suggest.map(d => ({
              text: d[0][0].toUpperCase() + d[0].slice(1)
            }))
          );
        });
    }
  }
};
</script>

<style scoped>
.root_cont {
  padding: 46px 0;
  text-align: center;
  position: relative;
}

.under_head {
  margin: auto;
  max-width: 570px;
}

.image_bg {
  background-image: url("/static/images/Kartaupodlozi.svg"); /* The image used */
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.7;
}

.image_bg.yellow-gradient {
  background-image: linear-gradient(to top, rgb(229, 211, 187) 0%, #fffff3 25%),
    url("/static/images/Kartaupodlozi.svg");
}

.container {
  position: relative;
  z-index: 2;
}

h5 {
  line-height: 150%;
}

@media (min-width: 576px) {
  #app h5 {
    font-size: 1.3rem;
  }
}
</style>
