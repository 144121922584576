<template>
  <div class="img_cont">
    <div
      ref="image_1"
      class="image-1 image_bg_aps"
      :class="{ img_blur: animated }"
    ></div>
    <div
      ref="grid_1"
      class="grid-1 image_bg_aps"
      :class="{ img_blur: animated }"
    ></div>
    <div
      ref="grid_2"
      class="grid-2 image_bg_aps"
      :class="{ img_blur: animated }"
    ></div>
    <img
      src="/static/images/ReportTilted.png"
      alt="TA Report"
      ref="image_2"
      class="image-2"
    />
        <div class="image_bg_aps blue_overlay"></div>
  </div>
</template>

<script>
import { TweenMax, TweenLite } from "gsap/all";

export default {
  name: "HomeHeaderAnimationMobile",
  data() {
    return {
      image_loaded: 0,
      animated: false
    };
  },
  mounted() {
    const vm = this;

    vm.image_loaded = 0;

    const data = {
      image_1: {
        url: "/static/images/CoverBrela22.jpg"
      },
      image_2: {
        url: "/static/images/ReportTilted.png"
      },
      grid_1: {
        url: "/static/images/GridBrela1.png"
      },
      grid_2: {
        url: "/static/images/GridBrela2.png"
      }
    };

    Object.keys(data).forEach(k => {
      const d = data[k];
      const img = new Image();
      img.onload = () => {
        vm.image_loaded++;
        if (vm.image_loaded === Object.keys(data).length) animate();
      };
      img.src = d.url;
    });
    TweenLite.set(vm.$el, { opacity: 0 });

    function animate() {
      TweenLite.set(vm.$el, { opacity: 1 });

      {
        TweenMax.from(vm.$refs.image_2, 2, {
          alpha: 0,
          x: 100,
          delay: 7.5,
          onStart: () => (vm.animated = true)
        });
      }
    }
  }
};
</script>

<style scoped>
.img_cont {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(4, 43, 93, 0); /* Used if the image is unavailable */
  overflow: hidden;
}

.image_bg_aps {
  background-position-x: center;
  background-position-y: 51%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.img_blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);

  -webkit-transition: 2s -webkit-filter linear;
  -moz-transition: 2s -moz-filter linear;
  -moz-transition: 2s filter linear;
  -ms-transition: 2s -ms-filter linear;
  -o-transition: 2s -o-filter linear;
  transition: 2s filter linear, 2s -webkit-filter linear;
}

.image-1 {
  background-image: url("/static/images/CoverBrela22.jpg");
  z-index: 1;
}

.grid-1 {
  background-image: url("/static/images/GridBrela1.png");
  z-index: 1;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);

  animation: grid-show 5000ms cubic-bezier(0, 0, 0.3, 1);
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
}

.grid-2 {
  background-image: url("/static/images/GridBrela2.png");
  z-index: 1;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);

  animation: grid-show 5000ms cubic-bezier(0, 0, 0.3, 1);
  animation-delay: 2500ms;
  animation-fill-mode: forwards;
}

@keyframes grid-show {
  from {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.image-2 {
  position: absolute;
  z-index: 1;
  height: 120%;
  right: 3%;
  bottom: -28%;
}

.blue_overlay {
  background-color: rgba(4, 43, 93, 0.2);
  z-index: 1;
}

@media (max-width: 992px) {
  .image-2 {
    right: -100px;
    height: 100%;
  }
}

@media (max-width: 576px) {
  .image-2 {
    right: -150px;
  }
}
</style>
