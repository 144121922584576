<template>
  <div>
    <div class="blue_bg root_cont">
      <div class="img_cont">
        <div class="image-1 image_bg_aps"></div>
        <!--        <div class="blue_overlay image_bg_aps"></div>-->
      </div>
      <div class="container">
        <div class="main_cont">
          <h2 class="text-center mb-4 pb-3 narrow">
            <span v-html="t.head"/>
          </h2>
          <HomeCounter />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head": "REGISTERED USER SEARCH RESULTS TO DATE:"
  },
  "hr": {
    "head": "REZULTATI PRETRAGE REGISTRIRANIH KORISNIKA DO DANAS:"
  },
  "es": {
    "head": "RESULTADOS DE LA BÚSQUEDA DE LOS USUARIOS REGISTRADOS HASTA LA FECHA:"
  }
}
//</i18n>

import "intersection-observer";
import { TweenMax, TweenLite, Linear, EaselPlugin } from "gsap/all";
import * as d3 from "d3";

import HomeCounter from "./HomeCounter"

export default {
  name: "HomeCounterCont",
  components: {HomeCounter},
  data() {
    return {
      t: t[this.$lang]
    };
  },
  mounted() {
    const vm = this;

  },
  methods: {}
};
</script>

<style scoped>
.root_cont {
  padding-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.img_cont {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(4, 43, 93, 0); /* Used if the image is unavailable */
  overflow: hidden;
}

.image_bg_aps {
  background-position-x: center;
  background-position-y: 39%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.image-1 {
  background-image: url("/static/images/PozadinaCounter.jpg");
  z-index: 1;
}

.container {
  position: relative;
  z-index: 1;
}

.main_cont {
  padding: 40px 0;
}

.narrow {
  max-width: 450px;
  margin: auto;
}

[data-w="img1"] {
  position: relative;
  height: 100%;
}

[data-w="img2"] {
  position: relative;
  height: 81%;
}

[data-w="img3"] {
  top: 10%;
  position: relative;
  height: 80%;
}

@media (max-width: 576px) {
  .root_cont {
    height: initial;
  }

}
</style>
